.callToAction {
  @apply my-8 xl:my-16;

  &.alignWide {
    @apply align-wide;

    & > * {
      @apply [max-width:unset];
    }

    & :global(.hero__content) {
      @apply container;
    }
  }

  &:has(:global(.cta-article)) {
    @apply h-full;
  }

  /* Article CTAs */
  & :global(.cta-article) {
    @apply h-full p-8;

    & * {
      @apply text-center;
    }

    & p {
      @apply m-0 font-medium;
    }

    & h3 {
      @apply m-0 font-bold uppercase;
    }

    & :global(.button-group) {
      @apply m-0 mt-4 w-full justify-center p-0;

      & :global(.button) {
        @apply w-[294px] font-secondary;
      }
    }

    & p ~ div {
      @apply m-0;
    }

    & p + :global(.button-group) {
      @apply mt-1;
    }
  }
}
